.helper-text {
  opacity: 0.8;
}
.instant-book-desc {
  margin: 0px 0px 40px 0px;
}

.card-instant-booking {
  // border: 3px solid var(--primary-color);
  text-align: center;
  background: var(--accent-color);
  opacity: 0.95;
  border: 0px;
}

.instant-card-container {
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(270deg, #BCD8F0 0%, #E1A3AA 49%, #CC95B4 100%);
  border: 1px solid var(--light-accent-color);
  @media only screen and (max-width: 768px) {
    //768px
    padding: 6px;
  }
}

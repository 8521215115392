.btn-boost {
  //width: 300px;
  font-weight: bold;
 
}

.boost-card {
  text-align: center;
  transition: transform 400ms cubic-bezier(0.55, 1.65, 0.545, 1.65), background 300ms ease-in-out;
  background: var(--accent-color);
   opacity: .95;
}




#normal_login_ageGroups {
  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    .ant-checkbox-wrapper {
      margin-inline-start: 0px;
    }
  }
}
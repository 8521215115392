.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 68px;
}

.title {
  color: var(--dark-main);
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  margin: 0;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  color: var(--dark-main);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 100px;
  padding: 18px 24px;
  cursor: pointer;
}

.active {
  border-color: var(--pink);
  background: var(--light-accent-color);
}

.tableWrapper {
  display: flex;
  justify-content: center;
}

.table {
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  :global(.os-scrollbar .os-scrollbar-handle) {
    background-color: var(--pink);
  }
}

.tableRow {
  display: grid;
  grid-template-columns: repeat(8, 155px);
  padding: 17px 45px;
  justify-content: center;
  gap: 15px;
}

.tableCell {
  color: var(--dark-main);
  font-weight: 600;
  font-size: 21px;
  line-height: 46px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;

  & > p {
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.tableCellIndicator {
  background-color: rgba($color: #ffffff, $alpha: 0.05);
  border-radius: 100px;
}

.loaderWrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .tableRow {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 21px;
    line-height: 26px;
    align-self: flex-start;
    margin-bottom: 21px;
  }
}

@media screen and (max-width: 550px) {
  .filters {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    padding: 8px;
  }

  .tableCell {
    font-size: 16px;
    line-height: 20px;
  }
}

.offer-list {
  tr {
    height: 75px;
  }
  .status-icon {
    font-size: 1.4em;
    margin-right: 0.6em !important;
  }
}

.creator-campaigns {
  margin-top: 32px;
  & h1 {
    font-size: 34px !important;
  }
}

@media screen and (max-width: 1500px) {
  .creator-campaigns {
    margin-top: 90px;
  }
}

@media screen and (max-width: 1440px) {
  .creator-campaigns {
    & h1 {
      font-size: 24px !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .creator-campaigns {
    margin-top: 110px;
  }
}

@media screen and (max-width: 550px) {
  .creator-campaigns {
    & h1 {
      font-size: 21px !important;
    }
  }
}

.fw-500 {
  font-weight: 500;
}

.flex {
  display: flex !important;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-content-center {
  align-content: center !important;
}

.align-self-start {
  align-self: start;
}
.align-item-baseline {
  align-items: baseline !important;
}
 
.hidden {
  display: none;
}

.nohover {
  pointer-events: none;
}
.align-self-center {
  align-self: center;
}

.align-self-left {
  align-self: flex-start;
}

.justify-self-end {
  justify-self: flex-end;
}

.image-rounded {
  border-radius: 50px;
}

.over-flow-y-auto {
  overflow-y: auto !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}
.text-bold {
  font-weight: bold !important;
}

.w-full {
  width: 100% !important;
}

.fw-600 {
  font-weight: 600;
}


  @media only screen and (max-width: 600px) {  //768px
    h1.ant-typography {
   font-size: 28px;
    }
    h2.ant-typography {
      font-size: 24px;
    }
    h3.ant-typography {
      font-size: 20px;
    }
}


svg:not(:root).svg-inline--fa:not(.user-fa),
svg:not(:host).svg-inline--fa:not(.user-fa)
//.user-avatar svg 
{
    margin-right: 0.3em;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-center {
  justify-content: center;
}

.text-align-none {
  text-align: unset !important;
}


.password-container {
  width: 80%;
  margin: auto;
}

.text-primary {
  color: var(--primary-color);
}

.text-capitalize {
  text-transform: capitalize;
}
.root {
  :global(.slick-arrow) {
    z-index: 1;
    width: 63px;
    height: 63px;
    & svg {
      fill: white;
      width: 100%;
      height: 100%;
    }
    &:nth-of-type(1) {
      left: -70px;
    }
    &:nth-of-type(2) {
      right: -70px;
      transform: rotate(180deg);
    }
  }
}

.title {
  margin: 0 !important;
}

@media screen and (max-width: 1500px) {
  .root {
    :global(.slick-arrow) {
      top: unset;
      bottom: -80px;
      &:nth-of-type(1) {
        left: 45%;
      }
      &:nth-of-type(2) {
        right: 45%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 28px !important;
  }

  .root {
    :global(.slick-arrow) {
      bottom: -60px;
      &:nth-of-type(1) {
        width: 30px;
        height: 30px;
        left: 47%;
      }
      &:nth-of-type(2) {
        width: 30px;
        height: 30px;
        right: 47%;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .root {
    :global(.slick-arrow) {
      top: unset;
      bottom: -80px;
      &:nth-of-type(1) {
        left: 40%;
      }
      &:nth-of-type(2) {
        right: 40%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .root {
    :global(.slick-arrow) {
      bottom: -50px;
      &:nth-of-type(1) {
        width: 30px;
        height: 30px;
        left: calc(100% - 60%);
      }
      &:nth-of-type(2) {
        width: 30px;
        height: 30px;
        right: calc(100% - 60%);
      }
    }
  }
  .title {
    font-size: 21px !important;
    margin: 0 !important;
  }
}

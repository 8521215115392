.root {
  width: 90% !important;
  :global(.ant-modal) {
    width: 90%;
  }
  :global(.ant-modal-header) {
    margin: 0;
    padding: 27px 67px !important;
  }
  :global(.ant-modal-body) {
    padding: 67px !important;
    background: var(--accent-color);
  }
  :global(h5.ant-typography) {
    font-size: 32px;
    font-weight: 600;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    border-radius: 10px;
    border: 1px solid #453b79;
    background: var(--light-accent-color);
    padding: 50px 60px;
  }
}

@media screen and (max-width: 768px) {
  .root {
    :global(.ant-modal-body) {
      padding: 25px !important;
    }

    :global(.ant-modal-header) {
      padding: 25px !important;
    }

    .content {
      padding: 25px;
    }
  }
}

@media screen and (max-width: 600px) {
  .root {
    :global(h5.ant-typography) {
      font-size: 21px;
      line-height: 25px;
      & span {
        max-width: 90%;
      }
      .float-right {
        float: unset !important;
      }
    }
  }
}

.search-box-container {
  align-items: center;
}

.search-campaigns-container {
  margin-bottom: 12px;

  .search-campaigns-item {
    input {
      height: 50px;
    }

    button {
      height: 50px;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: none !important;
    text-align: center;
  }
}

.search-user-container {
  display: flex;
  gap: 12px;

  .search-user-item {

    button {
      height: 50px;
      width: 50px;
    }

    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: none !important;
    text-align: center;
  }
}

.filter-user-item {
  display: flex;
  gap: 12px;
  button {
    font-weight: 600;
    width: unset !important;
    height: unset !important;
    min-width: 130px;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.range-box-container {
  margin-top: 12px;
}

.range-container {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 24px;
  justify-content: left;

  @media only screen and (max-width: 1326px) {
    grid-template-columns: auto auto auto;
    gap: 12px;
    justify-content: left;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 762px) {
    grid-template-columns: auto;
    gap: unset;
  }

}

.tagsContainer {
  width: 648px;
  @media only screen and (max-width: 1326px) {
    width: 636px;
  }
  @media only screen and (max-width: 762px) {
    width: 100%;
  }
}

.search-user-container .search-user-item .ant-input-suffix {
  display: none;
}
.range-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}
.section-title {
    gap: 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.select-search {
    min-width: 150px;
    @media only screen and (max-width: 393px) {
        min-width: 130px;
      }
}
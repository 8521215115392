.root {
  margin-top: 55px;
}

.tile {
  padding: 25px;
  border-radius: 25px;
  background: var(--light-accent-color);
  & > div {
    display: flex;
    gap: 34px;
  }
  :global(.os-scrollbar .os-scrollbar-handle) {
    background-color: #443877;
  }

  &:not(:first-child) {
    margin-top: 31px;
  }
}

.tileItemWrapper {
  width: 150px;
  flex-shrink: 0;
  &:not(:last-child) {
    padding-right: 24px;
    border-right: 1px solid #443877;
  }
}

.tileItem {
  padding-bottom: 5px;
}

.tooltipIcon {
  position: relative;
  margin-left: 10px;
  display: flex;
  align-items: center;
  & svg {
    cursor: pointer;
    & * {
      stroke: var(--dark-main);
    }

    &:hover {
      & * {
        stroke: var(--dark-main);
      }
    }
  }
}

.tooltip {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--dark-main);
  background: var(--dark-main);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  & :global(.ant-tooltip-inner) {
    background: none;
    box-shadow: none;
    padding: 0;
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;
  }
}

.tileItemValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  color: var(--dark-main);
  font-size: 30px;
  font-weight: 600;
}

.tileItemLabel {
  font-size: 18px;
  font-weight: 400;
  color: rgba($color: var(--dark-main), $alpha: 0.85);
  margin: 8px 0 0 0;
  text-align: unset;
  display: block;
}

@media screen and (max-width: 1440px) {
  .root {
    margin-top: 31px;
  }

  .tile {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .tileItemValue {
    font-size: 21px;
    line-height: 26px;
  }

  .tileItemLabel {
    font-size: 14px;
    line-height: 18px;
  }

  .tooltip {
    & :global(.ant-tooltip-inner) {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .root {
    margin-top: 24px;
  }

  .tileItemValue {
    font-size: 19px;
  }

  .tooltip {
    & :global(.ant-tooltip-inner) {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 550px) {
  .tile {
    & > div {
      flex-direction: column;
    }
  }

  .tileItemWrapper {
    padding-bottom: 21px;
    width: unset;
    &:not(:last-child) {
      padding-right: 0;
      border-right: unset;
      border-bottom: 1px solid #443877;
    }
  }

  .tileItemValue {
    font-size: 21px;
  }

  .tileItemLabel {
    font-size: 16px;
  }

  .tooltipIcon {
    width: 24px;
    height: 24px;
  }

  .tooltip {
    & :global(.ant-tooltip-inner) {
      font-size: 14px;
    }
  }
}

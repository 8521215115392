.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 68px;
}

.title {
  color: var(--dark-main);
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  margin: 0;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  color: var(--dark-main);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 100px;
  padding: 18px 24px;
  cursor: pointer;
  &:disabled {
    color: rgba($color: #ffffff, $alpha: 0.2);
  }
}

.active {
  border-color: var(--pink);
  background: var(--light-accent-color);
}

.targetWrapper {
  :global(.ant-row) {
    display: flex;
    flex-direction: column;
    :global(.ant-col) {
      text-align: start;
      flex: 1;
    }
    & label {
      font-size: 24px;
      margin-bottom: 16px;
      height: unset;
      align-items: flex-start;
      justify-content: flex-start;
      &::before,
      &::after {
        display: none !important;
      }
    }
  }
  &:nth-child(2) {
    margin-top: 34px;
  }
}

.countriesSelect {
  width: 100%;
  max-width: 55%;
  :global(.ant-select:not(.ant-select-customize-input).ant-select-selector) {
    height: unset !important;
  }
}

.saveButton {
  padding: 9px 34px;
  color: var(--dark-main);
  background-color: var(--pink);
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 10px;
  cursor: pointer;
  margin: 45px 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  min-height: 40px;
  &:disabled {
    background-color: rgba($color: #000830, $alpha: 0.4);
    cursor: not-allowed;
  }
}

@media screen and (max-width: 1280px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .title {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 21px;
    line-height: 26px;
    align-self: flex-start;
    margin-bottom: 21px;
  }
}

@media screen and (max-width: 550px) {
  .filters {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    padding: 8px;
  }

  .targetWrapper {
    &:nth-of-type(1) {
      :global(.ant-row) {
        label {
          font-size: 18px;
        }
      }
    }
    &:nth-of-type(2) {
      :global(.ant-row) {
        label {
          font-size: 24px;
        }
      }
    }
    :global(.ant-checkbox-wrapper) {
      font-size: 21px !important;
    }
  }

  :global(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
  }

  .saveButton {
    font-size: 14px;
    line-height: 22px;
    margin: 51px auto;
    width: 100%;
    max-width: 80%;
    justify-content: center;
    align-items: center;
    padding: 9px;
  }
}

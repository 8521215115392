

.sub-nav-wrapper {
    width: 100%;
    line-height: 30px;
}

.d-sub-nav {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    .ant-typography {
        font-weight: 500;
        font-size: 12px;
    }
}

@media only screen and  (min-width:576px)  and (max-width:767px) {
    .d-sub-nav {
        line-height: 30px!important;
    }
}


@media only screen and (max-width:575px) {
    .d-sub-nav {
        line-height: 20px!important;
    }
}
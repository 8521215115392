.float-label {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .required {
        opacity: .5;
    }

    .placeholder {
        display: flex;
        position: absolute;
        opacity: .5;
        bottom: 2px;
        left: 12px;
        z-index: 1;
    }

    .txt-placeholder {
        padding-top: 20px;
        bottom: 25px !important;
        top: 4px;

    }

    textarea.ant-input {
        padding-top: 20px;
    }

    .label {
        font-size: 15px;
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 12px;
        transition: 0.2s ease all;
        z-index: 2;
    }

    .ant-picker-input>input {
        top: 11px;
    }

    .fld-date {
        top: 9px !important;
    }

    .label-float {
        top: 6px;
        font-size: 11px;
    }

    .disabled {
        opacity: .6;
    }
}

/* Login Component */

.login-card {
  padding: 40px 200px !important;
  width: 100%;
  float: "right";
  height: 100%;
}

.login-card .ant-card-body {
  padding: 0 !important;
}

@media only screen and (max-width: 1024px) {
  .login-card {
    padding: 48px 120px !important;
  }
}

@media only screen and (max-width: 768px) {
  .login-card {
    padding: 12px 30px !important;
    height: 100vh !important;
  }
}

.login-cover-image {
  object-fit: cover;
  height: 450px !important;
}

/* Signup component */
.signup-card .ant-card-body {
  padding: 0 !important;
}

.signup-card {
  padding: 40px 160px !important;
  width: 100%;
  float: "right";
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .signup-card {
    padding: 48px 120px !important;
  }
}

@media only screen and (max-width: 768px) {
  .signup-card {
    padding: 12px 30px !important;
    height: 100vh !important;
  }
}

.signup-cover-image {
  object-fit: cover;
  height: 600px !important;
}

.terms {
  display: flex;
  align-items: center;
  justify-content: center;
}

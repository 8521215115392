.fa-spinner {
    font-size: 40px;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
    text-shadow: none !important;
    font-size: 20px;
}

.ant-spin-container::after {
    background: none !important;
}
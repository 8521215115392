//.hToTyq, .hbxhyW , .jpVoKi,.lbOKxm 
.payment-container  > div:first-child {    
    background-color: var(--light-accent-color) !important;
    border: 1px solid #534691 !important;
    box-shadow: none !important;
    border-radius: var(--border-radius) !important;
    justify-content: space-between;
    &:active, &:hover  {
        border: 1px solid var(--primary-color) !important; 
       }
    input {
        background-color: var(--light-accent-color) !important;
        color: var(--dark-text) !important;
    }
    input#cardNumber {
        width: auto;
    }
  /*  .bvNuLT  {
       border: none !important;
    } */
  
}


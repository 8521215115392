.card-list {
    padding: 10px;
    margin-bottom: 10px;
}


.card-list-item {
    margin-bottom: 10px;
    
    .ant-card-body {
        padding: 0;

        .ant-list-item {
            margin: 0
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 24px;
        height: 70px;
        margin-bottom: 10px;
        transition: all 400ms cubic-bezier(0.55, 1.65, 0.545, 1.65);
        cursor: pointer;

        .svg-inline--fa {
            font-size: 2rem;
        }

        .card-dot {
            font-size: 0.8em;
        }

        .ant-list-item-meta {
            align-items: center !important;
        }

        .ant-list-item-meta-description {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}



.name-card-item {
    font-size: 14px;
    bottom: 5px;
    position: relative;
}
@import "./components/assets/styles/space";
@import "./components/assets/styles/common";
@import "@fontsource-variable/open-sans";

@font-face {
  font-family: 'MadeGentle';
  src: url('./fonts/madeGentle.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Open Sans Variable', sans-serif;
    background-color: #FFFFFF;
  width: unset !important;
}

:root {
  --dark-main: #000830;
  --dark-text: #151515;
  --light-text: #ffffff;
  --muted-text: rgba(#000830, 0.65);
  --border-radius: 8px;
  --primary-color: #db4e94;
  --accent-color: white;
  --light-accent-color: #F7F8F9;
  --border-radius: 8px;
  --border-color: #575757;
  --pink: #f853bd;
    font-family: 'Open Sans Variable', sans-serif;
}

.menu-bg {
  background: var(--light-accent-color);
}

.center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.fw-600 {
  font-weight: 600;
}

.text-right {
  text-align: right !important;
}
.ant-typography {}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-family: "MadeGentle", serif !important;

  & span {
    font-family: "MadeGentle", serif !important;
  }
}
.ant-typography a {
  font-size: 16px !important;
  text-decoration: underline !important;
  font-weight: 400;
}

.ant-btn-primary,
.ant-btn-default {
  min-height: 40px;
  font-size: 14px !important;
}

.ant-layout-header,
.ant-menu {
  height: 80px !important;
  padding: 0px !important;
  line-height: 80px !important;
}

.ant-card-body p {
  text-align: center;
}

.ant-card-body,
.text-left {
  text-align: left !important;
}

.choose-type {
  padding: 14px 20px !important;
  gap: 16px !important;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px !important;
  width: 4px !important;
}

.ant-steps-item-icon {
  font-weight: 600 !important;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  display: none;
}

.ant-menu-title-content .ant-image {
  margin-top: -8px !important;
}

.ant-divider-horizontal {
  display: none !important;
}

.container {
  margin: auto !important;
}
.wrapper-with-background {
  background: var(--light-accent-color);
}

.header-bg {
  width: 100%;
  top: 0;
  z-index: 1;
  padding: 0px;
}

.mobile-container {
  max-width: 664px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.page-wrapper {
  padding: 24px;
  margin-top: 1.5rem !important;
  @media only screen and (max-width: 600px) {
    padding: 6px;
  }
}

.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 50px;
  align-items: center;
}

// Input error message
.ant-form-item-explain-error {
  text-align: left;
}

.ant-input-affix-wrapper {
  position: unset;
  padding-top: 24px;
}

.ant-picker {
  height: 50px !important;
  align-items: center;
}

.ant-picker-input > input:placeholder-shown {
  display: none;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-form-item {
  margin-bottom: 8px;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon {
  position: absolute;
  top: 50% !important;
  right: 30px !important;
  z-index: 1;
  width: 12px !important;
  height: 12px !important;
}

.ant-radio-checked::after {
  width: 2px !important;
  height: 2px !important;
  top: 12px !important;
  left: 12px !important;
}

.ant-radio-group label {
  font-size: 18px !important;
}

.ant-upload.ant-upload-select-picture-card {
  background: none !important;
  border-radius: 50% !important;
}
.music-nav-menu {
  @media only screen and (min-width: 1401px) {
    padding: 0px !important;
  }
  @media only screen and (min-width: 761px) and (max-width: 1400px) {
    padding: 0px 24px !important;
  }
  @media only screen and (max-width: 760px) {
    padding: 0px 12px !important;
  }
}

.profile-content {
  width: 50% !important;
}

@media only screen and (max-width: 768px) {
  .ant-menu::before {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .profile-content {
    width: 100% !important;
  }
}

.ant-checkbox-inner {
  height: 22px !important;
  width: 22px !important;
}

.ant-checkbox-inner::after {
  top: 46% !important;
  height: 15.142857px !important;
}

/* .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 12px !important;
} */

.ant-image {
  vertical-align: middle;
}

.profile-img {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50%;
  border: 2px solid var(--light-accent-color);
}

.profile-img.c-img {
  height: 100px !important;
  width: 100px !important;
  border-radius: 50%;
}

.timeline-img {
  height: 70px !important;
  width: 70px !important;
  border-radius: 50%;
}

.img-cover {
  object-fit: cover;
}

.profile-pic-container {
  margin-bottom: 25px;
  width: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect:focus {
  outline: none !important;
}

a:hover,
.actionHover:hover {
  text-decoration: underline;
}

a.noHover {
  text-decoration: none !important;
}

.ant-popover-buttons .ant-btn {
  height: 24px !important;
  font-size: 12px !important;
}

.h-100 {
  height: 100px !important;
}

.ant-select {
  text-align: left;
}

.back-arrow {
  font-size: 36px;
}

.margin-auto {
  margin: auto;
}

.ant-avatar.ant-avatar-icon {
  font-size: 30px;
}

.stripe-information {
  border-radius: 5px;
  padding: 20px 135px 20px 25px;
  // background: url(/img/stripe-grey.svg) right 25px center no-repeat;
  background-size: 80px auto;
}

.user-fa {
  font-size: 24px;
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1350px;
  }
}

/*
.ant-menu-overflow {
    & li:nth-child(2) {
        margin-left: auto;
    }
}
*/

.ant-menu-item-only-child {
  margin-top: 2px !important;
}

@media only screen and (max-width: 992px) {
  .ant-menu-item-only-child {
    text-align: right;
  }
}

@media only screen and (max-width: 760px) {
  .ant-menu-overflow {
    & li:nth-child(2) {
      margin-left: auto;
    }
  }
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.ant-menu-light {
  color: #8492A6;
}
.offer-status-helper-container{
    margin-bottom: 20px;
    margin-top:20px;
}

.offer-status-helper-tile{
    display:flex; 
    gap: 20px;
}

.status-text {
    flex: 2;
    display:flex;
    flex-direction: column;
    h4 {
        margin-bottom: 0px;
        margin-top: 0;
    }
}

.status-activity{
    flex: 1.5;
    // background-color:lightblue;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
 
}

.monkey-anim-width {
    width: 100px;
}

.timer-anim-width {
    width: 25px;
}

.time-remaining-container{
    background-color: #f5f5f550;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;    // border: 1px #15151590 solid;
    border-radius: 10px;
    // padding:5px 10px 5px 010px;
    // height:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    color: #151515;
}

.timer-animation{
    margin-top: 11px;
    margin-bottom: 5px;
}

.status-helper-button{
    text-align: right;
    width:100%;
    display: flex;
    justify-content: flex-end;


    Button{
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
}

.shake-animation{
  animation: shake 0.5s;
  animation-iteration-count: 2;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }


@media only screen and (max-width: 600px) {
    .offer-status-helper-tile{
        flex-direction: column;
        text-align: center;
    }

    .offer-status-helper-tile{
        align-items: stretch;
    }
}
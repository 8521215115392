.card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    text-align: left;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
}
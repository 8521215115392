.root {
  margin-top: 86px;
}

.buttons {
  display: flex;
  align-self: center;
  justify-content: center;
}

.modalButton {
  color: var(--dark-main);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 9px 34px;
  border-radius: 10px;
  border: 1px solid #534591;
  background: var(--light-accent-color);
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .root {
    margin-top: 42px;
  }
  .modalButton {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1280px) {
  .root {
    margin-top: 24px;
  }
  .modalButton {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 950px) {
  .modalButton {
    padding: 9px;
  }
}

@media screen and (max-width: 768px) {
  .buttons {
    flex-direction: column;
  }
  .modalButton {
    &:not(:last-child) {
      margin: 0 0 16px 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .root {
    margin-top: 42px;
  }

  .modalButton {
    font-size: 14px;
    box-shadow: 0px 2px 0px 0px #5a51649e;
  }
}

.search-box-container {
    align-items: center;
  }
  
  .search-campaigns-container {
    margin-bottom: 12px;
  
    .search-campaigns-item {
      input {
        height: 50px;
      }
  
      button {
        height: 50px;
      }
  
      &:nth-child(2) {
        text-align: right;
      }
    }
  
    @media only screen and (max-width: 600px) {
      grid-template-columns: none !important;
      text-align: center;
    }
  }
  
  .search-title-container {
    display: flex;
    gap: 12px;
  
    .search-title-item {
  
      button {
        height: 50px;
        width: 50px;
      }
  
      &:nth-child(1) {
        width: 100%;
      }
  
      &:nth-child(2) {
        text-align: right;
      }
    }
  
    @media only screen and (max-width: 600px) {
      grid-template-columns: none !important;
      text-align: center;
    }
  }
  
  .filter-title-item {
    display: flex;
    gap: 12px;
    button {
      font-weight: 600;
      width: unset !important;
      height: unset !important;
      min-width: 130px;
    }
  
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  
  .sound-filter-box-container {
    margin-top: 12px;
  }
  
  .sound-filter-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
    justify-content: left;
  
    @media only screen and (max-width: 1326px) {
      grid-template-columns: auto auto auto;
      gap: 12px;
      justify-content: left;
    }
  
    @media only screen and (max-width: 1200px) {
      grid-template-columns: auto auto;
    }
  
    @media only screen and (max-width: 762px) {
      grid-template-columns: auto;
      gap: unset;
    }
  
  }
  
  .slide-up {
    overflow: hidden;
    transform: translateY(0);
    transition: .5s ease-in-out;
    height: 0px;
  }
  
  .active {
    display: grid;
    height: auto;
    margin-bottom: 10px;
  
  }
  
  .slide-up>div,
  .active>div {
    transform: translateY(-100%);
    transition: .5s ease-in-out;
  }
  
  .active>div {
    transform: translateY(0);
  }
  
.analyzeIcon > .anticon-play-circle {
    color: var(--white) !important;
}

.analyzePink {
    color: var(--secondary-color) !important;
} 

.analyse-card.ant-card {
    background: transparent !important;
    text-align: center;
    border: 3px solid var(--accent-color) !important;
}

.analyse-card .ant-card-meta-detail > .ant-card-meta-description {
    color: var(--white) !important;
}

@media only screen and (min-width: 1024px) {
    .analyse-card .ant-card-body {
        height: 150px;
    }
}

.white-text.anticon-play-circle {
    color: var(--white) !important;
}

.ant-input-search-button .anticon-search {
    display: none !important;
}

.analyze-anim-width {
    height: '400px';
    width: '400px';
}
.mh-380 {
    min-height: 380px;
}
.mw-130 {
   min-width: 130px;
}

.responsive-menu-container {
    display: flex;
    flex-direction: column;
    min-width: 141px
}

.no-underline:hover {
    text-decoration: none;
}

/** Ant Result **/

.ant-result {
    padding: 24px;
}

.ant-result-title {
    font-size: 30px;
}

.ant-result-subtitle {
    font-size: 16px;
}

.result-tick .fa-circle-check {
    height: 72px;
}

.card-result {
    font-size: 60px;
    margin-bottom : 0px !important;
  }
  



/***end ***/
.shipping-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
}
.shipping-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 24px;
    margin-top: 1em;
    @media only screen and (max-width: 600px) {
        justify-content: center;
    }
}
.shipping-list-item {
    text-align: left;
    padding-left: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    min-height: 70px;
    cursor: pointer;
    .content-text, .label-text {
        margin-bottom: 0px;
    }
    .location-icon {
        font-size: 15px;
    }
    .ant-list-item-meta-avatar {
        margin-right: 0px;
    }
}
.other-user-tile-reverse {
    flex-direction: row-reverse;
   text-align: right;
}

.timeline-status-helper-container {
    margin: 20px 0px 20px 0px;
}

// Bad practice but I have no choice right now.
.secondary-text{
    font-size: 12px;
    color: var(--muted-text);
}
.root {
  background: var(--light-accent);
  min-height: 200px;
  margin-top: 42px;
  :global(.ant-card-body) {
    padding: 44px 49px 50px;
  }
}

.headerWrapper {
  display: grid;
  align-items: center;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  font-size: 32px;
  font-weight: 600;
  grid-row: 1;
  grid-column: 1;
}

.downloadButton {
  padding: 9px 34px;
  color: var(--light-text);
  background-color: var(--pink);
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: auto;
  width: 155px;
  grid-row: 1;
  grid-column: 2;
  height: 40px;
  :global(.ant-spin-dot-item) {
    background-color: var(--ligh);
  }
}

.loaderWrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  .title {
    font-size: 24px;
    line-height: 46px;
  }

  .downloadButton {
    padding: 8px 34px;
    font-size: 13px;
    line-height: 22px;
    max-width: 131px;
  }
}

@media screen and (max-width: 1280px) {
  .root {
    :global(.ant-card-body) {
      padding: 44px 34px 30px;
    }
  }
  .title {
    font-size: 21px;
  }
}

@media screen and (max-width: 550px) {
  .root {
    margin-top: 21px;
    :global(.ant-card-body) {
      padding: 25px;
    }
  }
  .headerWrapper {
    padding: 0;
  }

  .title {
    font-size: 17px;
    align-self: flex-start;
    grid-row: 3;
    line-height: 21px;
    grid-column: 1/3;
  }

  .downloadButton {
    margin: 0 0 43px 0;
    width: 100%;
    max-width: unset;
    font-size: 14px;
    grid-column: 1/3;
  }
}

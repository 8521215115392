.root {
  margin-top: 31px;
  grid-column: 1/3;
  :global(.ant-picker-input > input:placeholder-shown) {
    display: block;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-accent-color);
  padding-bottom: 24px;
}

.button {
  color: var(--dark-main);
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 100px;
  padding: 18px 24px;
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.active {
  border-color: var(--pink);
  background: var(--light-accent-color);
}

.dateFilterWrapper {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.dateFilterButton {
  padding: 9px 34px;
  color: var(--light-text);
  background-color: var(--pink);
  border: none;
  outline: none;
  font-size: 18px;
  font-family: 700;
  line-height: 22px;
  box-shadow: 0px 2px 0px 0px rgba(32, 13, 0, 0.62);
  border-radius: 10px;
  cursor: pointer;
}

.datePickerPanels {
  :global(.ant-picker-panels) {
    flex-direction: column;
  }
}

@media screen and (max-width: 1440px) {
  .root {
    margin-top: 21px;
  }
  .button {
    padding: 8px 18px;
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1280px) {
  .root {
    margin-top: 14px;
  }

  .button {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .buttonsWrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
    border: unset;
  }

  .button {
    width: 100%;
    font-size: 14px;
    padding: 8px;
    &:not(:first-child) {
      margin: 16px 0 0 0;
    }
  }

  .dateFilterWrapper {
    margin: 16px 0 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .root {
    margin: 0;
  }
}

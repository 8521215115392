.brief-header{
    display:flex;
    flex-direction: column;
    margin-bottom: 20px;

    h4 {
      margin-top: 0
    }
}


.preview-container {
    margin-bottom: 10px;
    .title {
      font-weight: 600;
      font-size: 18px;
    }
    .value {
      opacity: 0.7;
    }
    .preview-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        text-align: left;
       }
  
      .grid-item {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        font-size: 18px;
        margin-bottom: 8px;
       
        @media only screen and (max-width: 600px) {
            align-items: baseline !important;
          }
      }
    }
  }



.stat.ant-space-align-center {
    justify-content: center !important;
    width: 100%;
  }

  .anticon-play-circle {
    font-size: 30px;
  }

  .right-arw {
    position: absolute;
    right: -30px;
    top: 50%;
  }

  .dash-card-wrap {
    display: flex !important;
    margin-top: 1rem;
  }
  .dashboard-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px !important;
    .ant-avatar {
      width: 50px !important;
      height: 50px !important;
      font-size: 50px !important;
      margin-right: 0.3em;
      
    }
  }

  .sub-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .dash-offer-card {
    margin: 0 14px;
    flex-grow: 1;
    
    .dashboard-box-amount{
      font-size: 60px !important;
      font-weight: 600;
    }
    .dashboard-box-bread {
      font-size: 16px;
      margin-bottom: 5px;
    }
    
  }

  .incomplete-campaign{
    color: #D46A6A;
  }

  .completed-campaign{
    color: #55AA55
  }
  
  @keyframes jump {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.6) translateY(0); }
    30%  { transform: scale(.6,1.1) translateY(-3px); }
    50%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg)  }
    100%  { transform: rotate(360deg)}
  }
  
  @media only screen and (max-width: 924px) {
    .dash-card-wrap {
      flex-direction: column !important;
    }
    .dashboard-title {
      font-size: 30px !important;
      margin-left: 10px !important;
      display: flex;
      align-items: center;;
    }
    .dash-offer-card {
      margin: 14px 0;
      flex-grow: 1 !important;
      .dashboard-box-amount {
        font-size: 25px !important;
      }
      .dashboard-box-bread {
        font-size: 16px;
      }
    }
  }  

  .marketer-fa.fa-user-circle {
    font-size: 60px;
    border-radius: 50%;
  }

 
  .thumbnail-container {
    position: relative;
    .icon {
      position: absolute;
      top: 45%;
      left: 40%;
      border-radius: 20px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 1.5rem;
        margin-right: 0;
      }
    }
  }
.creatives {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.creativeItem {
  padding: 32px;
  border: 1px solid var(--dark-main);
  border-radius: 10px;
  flex: 1;
}

.titleWrapper {
  display: flex;
  align-items: center;
  margin: 0 0 21px 0;
}

.creativeTitle {
  color: #bd4581;
  font-size: 24px;
  line-height: 46px;
  font-weight: 600;
  margin: 0 38px 0 0;
}

.creativeStatusWrapper {
  display: flex;
  align-items: center;
}

.creativeStatus {
  color: #ffffff;
  font-size: 21px;
  line-height: 22px;
  margin-left: 16px;
}

.creativeField {
  max-width: 70%;
  &:not(:first-child) {
    margin-top: 42px;
  }
}

.label {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 16px;
  display: block;
}

.input {
  padding: 16px 21px;
  font-size: 21px;
}

.objectivesCheckboxes {
  margin-top: 42px;
  & span {
    text-transform: capitalize;
    font-size: 21px;
  }
}

.saveButton {
  padding: 9px 34px;
  color: var(--dark-main);
  background-color: var(--pink);
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  box-shadow: 0px 2px 0px 0px rgba(32, 13, 0, 0.62);
  border-radius: 10px;
  cursor: pointer;
  margin: 45px 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  min-height: 40px;
  &:disabled {
    background-color: rgba($color: #575757, $alpha: 0.4);
    cursor: not-allowed;
  }
}

.addNewButtonWrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
}

.addNewButton {
  color: var(--dark-main);
    border: 1px solid var(--dark-main);
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding: 10px 34px;
  background: var(--light-accent-color);
  border-radius: 10px;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.creativesLimit {
  font-size: 14px;
  color: var(--dark-main);
    border: 1px solid var(--dark-main);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  height: 34px;
  & span {
    padding: 10px;
  }
}

@media screen and (max-width: 1440px) {
  .creativeField {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .creativeField {
    max-width: unset;
  }
}

@media screen and (max-width: 768px) {
  .addNewButtonWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .creativesLimit {
    margin: 17px 0 0 0;
    height: unset;
    width: 100%;
    justify-content: flex-start;

    & span {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 550px) {
  .creativeTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .creativeStatus {
    font-size: 14px;
    line-height: 22px;
    margin-left: 4px;
  }

  .creativeField {
    max-width: unset;
  }

  .objectivesCheckboxes {
    gap: 16px;
    & span {
      font-size: 14px;
    }
  }

  .addNewButton {
    width: 100%;
    font-size: 14px;
    padding: 9px;
  }

  .label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .saveButton {
    width: 90%;
    margin: 42px auto 0;
  }

  .input {
    font-size: 14px;
  }
}

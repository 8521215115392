.deliverable-container{
    text-align: left;
    border-radius: 6px;
}

.deliverable-container-header {
    padding: 6px 15px;

}

.deliverable-container-content{
    max-height: 250px;
    padding: 10px;
    overflow-y: auto;

}
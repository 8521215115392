.budget-info-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // margin-top: 100px;
    row-gap: 20px;
  
    .budget-info-item {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
  
     
    }
    @media only screen and (max-width: 800px) {
      text-align: center;
      grid-template-columns: 1fr 1fr;
      .budget-info-item {
        align-items: center;
      }
    }
  
    @media only screen and (max-width: 600px) {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .budget-info-item {
        align-items: center;
      }
    }
  }
  @media only screen and (max-width: 600px) {
  .ant-slider-mark-text {
    font-size: 10px;
  }

  }

  .range-slider {
   padding: 0px 20px 50px 20px;
   @media only screen and (max-width: 992px) {  //768px
     padding: 0px 20px 20px 20px;
   }
  }

  .btn-budget {
    width: 100%;
    align-items: initial !important;
  }
.pieChartWrapper {
  padding: 0 150px;
  height: 400px;
  & > div {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 68px;
}

.title {
  color: var(--dark-main);
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  margin: 0;
}

.filtersWrapper,
.filters {
  display: flex;
  align-items: center;
  height: 60px;
}
.metricsSelectWrapper {
  display: flex;
  align-items: center;
  min-width: 200px;
  height: 60px;
  margin-left: 35px;
  :global(.ant-select) {
    height: 100%;
    width: 100%;
  }
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    height: 100% !important;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-accent-color);
  padding-bottom: 24px;
}

.button {
  color: var(--dark-main);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 100px;
  padding: 18px 24px;
  cursor: pointer;
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.active {
  border-color: var(--pink);
  background: var(--light-accent-color);
}

.loaderWrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .title {
    font-size: 21px;
    line-height: 26px;
    align-self: flex-start;
    margin-bottom: 21px;
  }

  .filtersWrapper {
    flex-direction: column;
    width: 100%;
    height: unset;
  }

  .filters {
    align-self: flex-start;
    height: 38px;
  }

  .button {
    padding: 8px 24px;
  }

  .metricsSelectWrapper {
    margin: 31px 0 0 0;
    width: 100%;
  }

  .pieChartWrapper {
    padding: 0;
    :global(.highcharts-title) {
      font-size: 12px !important;
    }
    :global(.highcharts-subtitle) {
      font-size: 12px !important;
    }
  }
}

@media screen and (max-width: 550px) {
  .button {
    font-size: 14px;
  }
}

@media screen and (max-width: 350px) {
  .filters {
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    height: unset;
  }
  .button {
    width: 100%;
  }
}

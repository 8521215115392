.find-creators.ant-card {
 // min-height: 350px;
   text-align: center;
   transition: transform 400ms cubic-bezier(0.55, 1.65, 0.545, 1.65), background 300ms ease-in-out;
    background: var(--accent-color);
    opacity: .95;
    &:hover {
      transform: translateY(-3px);
      background-position: 15% 80%;
      cursor: pointer;
      opacity: 1;
    }
    .ant-card-body {
      padding: 12px 24px 12px 24px;
    }
}

.creator-price {
  padding: 4px 24px;
  white-space: nowrap;
  max-width: 100px;
  text-align: center;
  margin-bottom: 0px;
  box-shadow: none;
 
}

@media only screen and (min-width: 1024px) {
  .creator-card .ant-card-body {
    height: 120px;
    padding: 14px !important;
  }
}

.find-handle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
}

.creator-spin .ant-spin-dot {
  left: 25% !important;
}

.content-sec {
  display: flex;
 // margin-left: 16px;

  & p {
    text-align: left !important;
    font-size: 12px !important;
    margin: 0 0 5px;
  }
}

.price-container {
  display: flex;
  justify-content: flex-end;
}

.ant-card-actions {
  background: transparent !important;
	border: none !important;
  .price-container {
    margin-right: 16px;
    margin-top: 30px;
    .creator-price {
      margin: 0px  !important;  
    }
  }
}

.find-creator-modal {

  .creator-modal {
    position: relative;

    & .ant-col {
      position: static;
    }

    & .tiktok-container {
      & span {
        font-size: 12px !important;
      }
    }

    & .price-container {
      position: absolute;
      top: 0;
      right: 0;

    }
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .search-box {
    border: none !important;
    margin-bottom: 10px;

  }

}

.view-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  justify-content: space-around;
  flex-wrap: nowrap;

  @media only screen and (max-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }

  & .view-info-item {
    // padding: 0 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    & h5,
    p {
      margin: 0;
    }

    & p:last-child {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 680px) {
    flex-wrap: wrap;
    
    & .view-info-item {
      margin-bottom: 16px;
      align-items: center;
    }
  }
}

.tags-container {
  text-align: center;
  padding-bottom: 20px;
  line-height: 30px;;
}

.row-header-container {
  display: grid;
  grid-template-columns: 0.5fr 3.5fr 1fr;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  
  .header-item,  .offer-card {
    text-align: left;
  }
  .header-item {
      .fa-circle-user {
        width: 40px;
        height: 40px;
      }
  }
  @media only screen and (min-width: 600px) {
    grid-template-columns: auto 4.5fr auto;
    gap: 20px;
    // padding-right: 0px !important;

  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: none !important;
    gap: 20px;
    // padding-right: 0px !important;
    .offer-card{
      text-align: center;
    }
    .header-item {
      text-align: center;
      .creator-price {
        margin: auto;
        // margin-top: 15px;
      }
     

    }
  }
}



.row-header-container  .view-info-container {
  width: 350px;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: transparent !important;
  text-align: left;
  padding: 4px 0;
  .view-info-item {
    align-items: flex-start;
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
    .view-info-item {
      align-items: center
    }
  }
}

.info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  p {
    margin-bottom: 0;
  }

  .info-item {
    font-size: 18px;
    margin: 10px;
    background-color: var(--dark-main);
    
      .ant-typography {
        color: white;
        font-family: 'Open Sans Variable', sans-serif !important;
      }
    .value {
      font-weight: 600;
    }
    .title {
      opacity: 0.7;
    }

    .ico {
      font-size: 20px;
    }

    .ant-card-body {
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        margin-top: 0;
      }
    }
  }
}


.offer-card {
    text-align: center;
    flex: 0 0 100% !important;
}

@media only screen and (max-width: 1024px) {
    .offer-card {
        width: 100% !important;
    }
}

.creator-description, .info-container {
  background-color: white;
}

.creator-tags {
  width: 100%;
  min-height: 60px; 
  line-height: 30px; 
}

.mw-80 {
  min-width: 80px;
}
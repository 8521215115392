$sizes: 50;
$widthSizes: 300;

@mixin margin-classes {
  @for $i from 0 through $sizes {
     $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i}  {margin: $margin !important;}
    .ml#{$i} {margin-left: $margin !important;}
    .mr#{$i} {margin-right: $margin !important;}
    .mt#{$i} {margin-top: $margin !important;}
    .mb#{$i} {margin-bottom: $margin!important;}
    .mx#{$i} {margin-left: $margin; margin-right: $margin !important;}
    .my#{$i} {margin-top: $margin; margin-bottom: $margin !important;}
  }
}
@include margin-classes;

@mixin width-classes {
  @for $i from 1 through $widthSizes {
    $width: $i * 1px;
    .w#{$i} {width: $width !important;}
  }
}
@include width-classes;


@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {padding: $padding !important;}
    .pl#{$i} {padding-left: $padding !important;}
    .pr#{$i} {padding-right: $padding !important;}
    .pt#{$i} {padding-top: $padding !important;}
    .pb#{$i} {padding-bottom: $padding !important;}
    .px#{$i} {padding-left: $padding !important; padding-right: $padding !important;}
    .py#{$i} {padding-top: $padding !important; padding-bottom: $padding !important;}
  }
}
@include padding-classes;

@mixin border-classes {
  @for $i from 0 through $sizes {
     $border: $i * 0.25rem;
    .b#{$i}  {border: $border !important;}
    .bl#{$i} {border-left: $border !important;}
    .br#{$i} {border-right: $border !important;}
    .bt#{$i} {border-top: $border !important;}
    .bb#{$i} {border-bottom: $border !important;}
    .bx#{$i} {border-left: $border !important; border-right: $border !important;}
    .by#{$i} {border-top: $border !important; border-bottom: $border !important;}
  }
}
@include border-classes;

@mixin font-classes {
  @for $i from 1 through $sizes {
    $font: $i * 1px;
    .f#{$i} {font-size: $font !important;}
  }
}
@include font-classes;

@mixin line-height-classes {
  @for $i from 1 through $sizes {
    $lineHeight: $i * 1px;
    .lh#{$i} {line-height: $lineHeight !important;}
  }
}
@include line-height-classes;

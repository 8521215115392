.mh-70 {
  @media only screen and (min-width: 992px) {
    min-height: 70px;
  }
}
.mh-100 {
  @media only screen and (min-width: 992px) {
    min-height: 100px;
  }
}
.pd0 {
  @media only screen and (max-width: 600px) {
    padding: 0px;
    margin-bottom: 0px !important;
  }
}

.md0 {
    @media only screen and (max-width: 600px) {
      margin: 0px !important;
    }
  }

.boost-row .ant-row {
  @media only screen and (max-width: 600px) {
    gap: 12px;
    padding: 8px !important;
  }
}
.boost-default-card {
  padding: 6px;
  border-radius: 8px;
  background: linear-gradient(270deg, #BCD8F0 0%, #E1A3AA 49%, #CC95B4 100%);
  @media only screen and (max-width: 768px) {
    //768px
    padding: 6px;
  }
  .ant-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.boost.ant-card  {
    transition: transform 400ms cubic-bezier(0.55, 1.65, 0.545, 1.65), background 300ms ease-in-out;
    background: var(--accent-color);
    opacity: .95;
    &:hover {
      transform: translateY(-3px);
      background-position: 15% 80%;
      cursor: pointer;
      opacity: 1;
    }
    @media only screen and (max-width: 600px) {
        .ant-card-body {
      padding: 8px;
        }
    }
  }
.wrapper-with-background {
  background: var(--light-accent-color);
  padding: 24px;
  border-radius: 10px;
}
  

  
.creativeField {
  &:not(:first-of-type) {
    margin-top: 42px;
  }
}

.label {
  font-weight: 500;
  display: block;
  font-size: 24px !important;
  margin-bottom: 16px;
}

.input {
  padding: 16px 21px;
}

.objectivesCheckboxes {
  & span {
    text-transform: capitalize;
    font-size: 21px;
  }
}

.addButton {
  margin: 15px 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

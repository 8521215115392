.ant-timeline-item-tail {
    top: 0px;
}

.ant-timeline-item-content {
    top: 0px;
}

.ant-timeline-item {
    padding-top: 0px;
    padding-bottom: 0px;
}
.profile-section {
    margin-top: 1rem;
    h3 {
        margin-top: 0;
    }
}

.profile-image-centered {
    display: flex;
    justify-content: center;
}